<template>
  <div>
    <perseu-card :title="this.escola.id ? 'Editar Escola' : 'Nova Escola'">
      <template #title-left>
        <back-button @click="close" />
      </template>

      <template slot="content">
        <v-form ref="form">
          <v-text-field
            label="Nome"
            v-model.trim="escola.name"
            :rules="[$rules.required]"
          />
        </v-form>
      </template>

      <template slot="actions">
        <v-btn color="secondary" @click="save">Salvar</v-btn>
      </template>
    </perseu-card>
  </div>
</template>

<script>
import { create, update } from "@/services/escolas-service.js";

export default {
  props: {
    selectedEscola: {
      type: Object,
      default: () => ({
        name: "",
        visible: true,
      }),
    },
  },
  data: function () {
    return {
      escola: { ...this.selectedEscola },
    };
  },
  methods: {
    async save() {
      try {
        this.$store.dispatch("loading/openDialog");

        if (!this.$refs.form.validate()) return;

        if (this.escola.id) {
          const { data } = await update(this.escola);

          this.$emit("updated", data);
        } else {
          const { data } = await create(this.escola);

          this.$emit("created", data);
        }

        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    close() {
      this.$emit("closed");
    },
  },
};
</script>

<style></style>
